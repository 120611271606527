.editable-input {
  max-width: 22rem;
}
.label-item {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.02em;
  color: #83889d;
}

.text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.01em;
  color: #333758;
  white-space: wrap;
  text-transform: capitalize;
}
.status__active {
  width: max-content;
  height: 28px;
  background: #36ca8b;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  padding: 0.8rem 1.6rem;
}
.status__inactive {
  width: max-content;
  height: 28px;
  background: #fa4a4a;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  padding: 0.8rem 1.2rem;
}
.status__unverified {
  width: max-content;
  height: 28px;
  background: #F0A800;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  text-transform: capitalize;
  text-align: center;
  padding: 0.8rem 1.2rem;
}
