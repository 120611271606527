html {
    --white: #fff;
    --primary: #3e7eee;
    --primary-light: #f1f7fd;
    --blue: #3e7eee;
    --black: #23273b;
    --pending: #f0a800;
    --error: #fa4a4a;
    --red: #fa4a4a;
    --green: #36ca8b;

    --confetti-blue: #7996b3;
    --confetti-green: #759f87;
    --confetti-pink: #d18794;
    --confetti-purple: #9586ac;
    --confetti-yellow: #efc16d;


    --green-light: #f0fcf5;
    --deep_blue: #23273b;
    --bg: #fafcff;
    --grey-text: #bcbfca;
    --grey-100: #f3f4f6;
    --grey-150: #f2f2f2;
    --grey-250: #efefef;
    --grey-300: #d9d9d9;
    --grey-500: #999fa3;
    --grey-600: #83889d;
    --grey-700: #666e7d;
}

body {
    margin: 0;
    font-family: 'Karla', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  scroll-margin-block: 1rem;
}

.scroll-bar {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.scroll-bar::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

/* SIDEBAR */
.activeRoute {
  background-color: #3e7eee;
  border-radius: 5px;
  transition: 0.5s;
}

.activeRoute p {
  color: #fff !important;
}

.activeRoute svg path {
  fill: #fff !important;
}

.chakra-menu__menu-list {
    padding: 1.2rem !important;
    /* override chakra default styling */
    border-radius: 0.4rem;
}

.chakra-menu__menu-list .chakra-menu__menuitem {
    padding: 1rem 1.5rem;
    /* override chakra default styling */
    border-radius: 0.4rem;
    /* override chakra default styling */
    font-size: 1.4rem;
}

.dropDown .react-select__multi-value__remove {
  display: grid;
  place-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 0.2rem;
}

.dropDown .react-select__multi-value__remove:hover {
  background: rgba(0, 0, 0, 0.05);
}

.dropDown .react-select__multi-value__remove:hover svg {
  fill: #555;
}

.dropDown .react-select__clear-indicator {
  padding: 4px;
}

.dropDown .react-select__multi-value {
  box-shadow: 0 0.5rem 0.25rem rgba(0, 0, 0, 0.02);
  background: #fff;
  padding: 2px 2px 2px 4px;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  height: 2.4rem;
}

.dropDown .react-select__menu-list {
  padding: 1.2rem;
}

.dropDown .react-select__option {
  padding: 1rem;
  border-radius: 4px;
  font-weight: 400;
}

.dropDown .react-select-v2__multi-value {
  box-shadow: 0 0.5rem 0.25rem rgba(0, 0, 0, 0.02);
  background: #fff;
  padding: 2px 2px 2px 4px;
  border-radius: 2rem;
  display: flex;
  align-items: center;
}

.dropDown .react-select-v2__menu-list {
  padding: 1.2rem;
  height: 17rem;
}

.dropDown .react-select-v2__option {
  padding: 0rem;
  border-radius: 4px;
  font-weight: 400;
}

.dropDown .react-select-v2__multi-value__remove {
  display: grid;
  place-content: center;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
}

.react-select-v2__multi-value__label img {
  display: none;
}

.react-select__placeholder {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
}

.Toastify__progress-bar {
  height: 2px;
}

.suspense {
  position: relative;
  width: 100%;
  height: 100%;
  /*max-height: 24px;*/
  background: rgb(235, 235, 235);
  overflow: hidden;
}

.suspense::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 40px;
  top: 0;
  left: -32px;
  background: rgba(255, 255, 255, 0.9);
  filter: blur(16px);
  animation: suspense 0.75s 0.05s ease infinite;
}

@keyframes suspense {
    from {
        left: -32px;
    }

    to {
        left: calc(100% + 32px);
    }
}

[class*="line-clamp"] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: break-spaces;
}

.line-clamp-1 {
    -webkit-line-clamp: 1;
}

.spin {
    --duration: 0.75s;
    --easing: linear;
    animation: spin var(--duration) var(--easing) infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.container{
  max-width: 1440px;
  width: 90%;
  margin: auto;
}

img{
  max-width: 100%;
}

/* @media (max-width: 648px){
  .container{
    width: 95%;
  }
} */